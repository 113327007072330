<template>
  <div class="quotationShare" :class="{ qutationSmall: SearchType == 1 }">
    <div class="list-header">
      <div class="header-left">
        <div>{{ $t("lang.QText97") }}</div>
      </div>
    </div>
    <div class="list">
      <el-tabs v-model="firstTab" @tab-click="handleClick">
        <el-tab-pane
          :label="
            $i18n.locale == 'en_us' ? item.menuName : item.extend.extendS3
          "
          :name="item.value"
          v-for="(item, index) in quotationMenu"
          :key="index"
        >
          <k-second-header
            :data="item.children"
            :activeIndex="secondTab"
            :clickchange="false"
            @handleSelect="handleSelect"
            v-if="item.children.length > 0"
          ></k-second-header>
        </el-tab-pane>
      </el-tabs>
      <dwlist
        :url="source.quotation_api.findShareQuoteList"
        :query-params="queryForm"
        ref="postsList"
        @onloadSuccess="onloadSuccess"
        v-loading="listloading"

      >
        <el-table-column
          prop="quotationNo"
          :label="firstTab == '1' ? $t('lang.QText99') : 'ID'"
          min-width="180%"
        >
          <template slot-scope="scope">
            <div v-if="firstTab == '1'">
              <div>{{ scope.row.partnerName }}</div>
              <div>{{ scope.row.accountId }}</div>
            </div>
            <span v-if="firstTab == '2'">{{ scope.row.quotationNo }}</span>
            <span v-if="firstTab == '3'">{{ scope.row.fQuotationNo }}</span>
          </template>
        </el-table-column>

        <el-table-column
          prop="summry"
          :label="$t('lang.BText4')"
          min-width="220%"
        >
          <template slot-scope="scope">
            <div class="Summry">
              <div class="line1">
                {{ scope.row.quotationMainType }} -
                {{ scope.row.quotationType }}
              </div>
              <div class="line2">
                {{ scope.row.startPort }} ->
                {{ scope.row.endPort }}
              </div>
              <div class="line3" v-if="scope.row.containerDatas !== ''">
                <span
                  v-for="(item, index) in JSON.parse(scope.row.containerDatas)"
                  :key="index"
                  >{{ item.containerSize }}*{{ item.count }};
                </span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="shareSecrecy"
          label="Confidential"
          min-width="230%"
        >
          <template slot-scope="scope">
            <span v-if="scope.row.shareSecrecy == '0'">All public</span>
            <span v-if="scope.row.shareSecrecy == '1'"
              >Keep origin confidential</span
            >
            <span v-if="scope.row.shareSecrecy == '2'"
              >Keep freight & destination confidential</span
            >
            <span v-if="scope.row.shareSecrecy == '3'">All confidential</span>
          </template>
        </el-table-column>
        <el-table-column
          prop="headAmounts"
          :label="$t('lang.BText118')"
          min-width="260%"
        >
          <template slot="header">
            <div class="head-amounts">{{ $t("lang.BText118") }}</div>
          </template>
          <template slot-scope="scope">
            <div
              class="Amount"
              v-for="(item, index) in scope.row.totalAmount"
              :key="index"
            >
              <div class="amountType"><span v-if="index == 0">total</span></div>
              <div class="headCurrency">
                {{ item.currency }}
              </div>
              <div class="headAmount">
                {{ item.Amount }}
              </div>
            </div>
            <div
              class="Amount"
              v-for="(item, index) in scope.row.originAmount"
              :key="index"
            >
              <div class="amountType">
                <span v-if="index == 0">origin</span>
              </div>
              <div class="headCurrency">
                {{ item.currency }}
              </div>
              <div class="headAmount">
                {{ item.Amount }}
              </div>
            </div>
            <div
              class="Amount"
              v-for="(item, index) in scope.row.freightAmount"
              :key="index"
            >
              <div class="amountType">
                <span v-if="index == 0">freight</span>
              </div>
              <div class="headCurrency">
                {{ item.currency }}
              </div>
              <div class="headAmount">
                {{ item.Amount }}
              </div>
            </div>
            <div
              class="Amount"
              v-for="(item, index) in scope.row.destinationAmount"
              :key="index"
            >
              <div class="amountType">
                <span v-if="index == 0">destination</span>
              </div>
              <div class="headCurrency">
                {{ item.currency }}
              </div>
              <div class="headAmount">
                {{ item.Amount }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          prop="deliveryDate"
          :label="firstTab == '1' ? $t('lang.BText28') :( firstTab=='2'?$t('lang.QText99'): $t('lang.QText98'))"
          width="150px"
        >
          <template slot-scope="scope">
            <div
              class="RepliedDate"
              v-if="
                $i18n.locale == 'zh_cn' &&
                scope.row.deliveryDate !== '' &&
                firstTab == '1'
              "
            >
              {{ scope.row.deliveryDate.slice(0, 10) }}
            </div>
            <div
              class="RepliedDate"
              v-if="
                $i18n.locale == 'en_us' &&
                scope.row.deliveryDate !== '' &&
                firstTab == '1'
              "
            >
              {{ utils.zhDate_to_enDate(scope.row.deliveryDate).slice(0, 15) }}
            </div>
            <div v-if="firstTab != '1'">{{ scope.row.partnerName }}</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="expiredDate"
          :label="firstTab == '1' ? $t('lang.BText113') : ''"
          width="150px"
        >
          <template slot-scope="scope">
            <div
              class="RepliedDate"
              v-if="
                $i18n.locale == 'zh_cn' &&
                scope.row.expiredDate !== '' &&
                firstTab == '1'
              "
            >
              {{ scope.row.expiredDate.slice(0, 10) }}
            </div>
            <div
              class="RepliedDate"
              v-if="
                $i18n.locale == 'en_us' &&
                scope.row.expiredDate !== '' &&
                firstTab == '1'
              "
            >
              {{ utils.zhDate_to_enDate(scope.row.expiredDate).slice(0, 15) }}
            </div>
            <div v-if="firstTab != '1'">
              <span v-if="scope.row.shareFlag == ''">{{
                $t("lang.QText101")
              }}</span>
              <span v-if="scope.row.shareFlag == '0'">{{
                $t("lang.QText102")
              }}</span>
              <span v-if="scope.row.shareFlag == '1'">{{
                $t("lang.QText103")
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column min-width="280%" fixed="right" v-if="SearchType == 2">
          <template slot-scope="scope">
            <div class="RoleRight2">
              <el-button
                v-if="firstTab == '3' && scope.row.shareFlag == ''"
                @click="clickCancel(scope.row)"
                class="samll-list-primary"
                >{{ $t("lang.QText79") }}</el-button
              >
              <el-button
                v-if="firstTab == '1'"
                @click="Process(scope.row)"
                class="samll-list-primary"
                >{{ $t("lang.NText94") }}</el-button
              >
              <el-button
                v-if="firstTab != '1'"
                @click="Process(scope.row, '2')"
                class="samll-list-info"
                >{{ $t("lang.QText76") }}</el-button
              >
            </div>
          </template>
        </el-table-column>
      </dwlist>
    </div>
    <el-dialog
      v-if="showReviewFLag"
      :visible.sync="showReviewFLag"
      class="editDialog dw-dialog"
      width="70%"
      @close="closeDialog"
      :destroy-on-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <div class="dialog-header">
        <div class="dw-logo">
<!--			<svg-icon icon-class="dwLogo"></svg-icon>-->
		</div>
        <div class="dialog-title">
          <div class="row1 blod60">{{ $t("lang.NText73") }}</div>
          <!-- <div class="row2">
            ID:<span style="color: #fff"> {{ thisRow.quotationNo }}</span>
          </div> -->
        </div>
        <div class="titleDate">
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.NText75") }}</div>
            <div class="date">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(thisRow.updateDate)
              }}</span
              ><span v-else>{{ thisRow.updateDate }}</span>
            </div>
          </div>
          <div class="updateDate">
            <div class="updateTitle">{{ $t("lang.NText72") }}:</div>
            <div class="date2" v-if="thisRow.expiredDate">
              <span v-if="$i18n.locale == 'en_us'">{{
                zhDate_to_ehDate(thisRow.expiredDate)
              }}</span
              ><span v-else>{{ thisRow.expiredDate }}</span>
            </div>
          </div>
        </div>
        <div class="close" @click="showReviewFLag = false">
          <svg-icon class="close-icon" icon-class="close"></svg-icon>
        </div>
      </div>
      <div class="el-dialog-main" ref="dwDialogMain">
        <share-dialog
          :quotationData="JSON.stringify(thisRow)"
          :stateView="firstTab == '1' ? 'RP' : ''"
          @Process="ProcessSuccess"
        ></share-dialog>
      </div>
    </el-dialog>

    <!-- <dw-check-box
      :showCheckBox="showSaveTemplate"
      @Cancel="showSaveTemplate = false"
      @Save="templateRename"
      :primaryButton="$t('lang.SText13')"
    >
    </dw-check-box> -->
    <dw-check-box
      :showCheckBox="cancelFlag"
      @Cancel="cancelFlag = false"
      @Save="Cancel"
      :CancelButton="$t('lang.QText79')"
      :primaryButton="$t('lang.yes')"
      :loading="cancelloading"
    >
      {{ $t("lang.QText104") }}
    </dw-check-box>
  </div>
</template>

<script>
import DwCheckBox from "../../../components/dwCheckBox/dwCheckBox.vue";
import dwlist from "../../../components/dwList/dwlist.vue";
import test from "../../../test/test";
import KSecondHeader from "../../../components/kHeader/kSecondHeader.vue";
import utils from "../../../utils/util";
import editDialog from "../aQuote/edit.vue";
import Review from "../aQuote/Review.vue";
import ShareDialog from "./quotationDialog.vue";
export default {
  components: {
    dwlist,
    DwCheckBox,
    editDialog,
    Review,
    KSecondHeader,
    ShareDialog,
  },
  data() {
    return {
      quotationMenu: [
        {
          menuName: "Pending",
          extend: {
            extendS3: "处理中",
          },
          value: "1",
          children: [],
        },

        {
          menuName: "Shared with me",
          extend: {
            extendS3: "分享给我的",
          },
          value: "2",
          children: [
            {
              menuName: "Sharing",
              extend: {
                extendS3: "共享中",
              },
              value: "1",
            },
            {
              menuName: "Expired",
              extend: {
                extendS3: "已过期",
              },
              value: "2",
            },
          ],
        },
        {
          menuName: "My shared",
          extend: {
            extendS3: "我分享的",
          },
          value: "3",
          children: [
            {
              menuName: "Sharing",
              extend: {
                extendS3: "共享中",
              },
              value: "1",
            },
            {
              menuName: "Expired",
              extend: {
                extendS3: "已过期",
              },
              value: "2",
            },
          ],
        },
      ],
      firstTab: "1",
      secondTab: "1",
      utils: utils,

      listData: test.listData,
      listloading: false,
      queryForm: {},
      userInfo: {},
      showDelete: false,
      showSaveTemplate: false,
      quotationData: {},
      total: "0",
      quotationTypeObj: {},
      thisRow: {},
      signOut: false,
      showReviewFLag: false,
      dialogTitle: "",
      showReview: false,
      showEdit: false,
      cancelFlag: false,
      cancelloading: false,
    };
  },
  computed: {
    SearchType() {
      if (window.innerWidth < 1660) {
        return 1;
      } else {
        return 2;
      }
    },
  },

  created() {
    this.$session("userInfo").get((value) => {
      if(value){
        this.queryForm.companyId = value.companyid;
      }
    });
    this.queryForm.quoTab = "1";
    this.$session("quotationType").get((value) => {
      value.forEach((item) => {
        this.quotationTypeObj[item.dictValue] = item.dictLabel;
        if (item.children.length > 0) {
          item.children.forEach((item2) => {
            this.quotationTypeObj[item2.dictValue] = item2.dictLabel;
          });
        }
      });
    });
  },
  methods: {
    query() {
      this.$refs.postsList.query();
    },
    onloadSuccess(data) {
      this.total = data.total;
    },
    zhDate_to_ehDate(e) {
      return utils.zhDate_to_enDate(e);
    },
    handleClick(tab, event) {
      this.secondTab = "0";
      this.queryForm.quoTab = this.firstTab;
      if (this.quotationMenu[parseInt(this.firstTab) - 1].children.length > 0) {
        this.queryForm.quoTab = this.firstTab + (parseInt(this.secondTab) + 1);
      }
      this.query();
    },
    handleSelect(num) {
      this.secondTab = num;
      this.queryForm.quoTab = this.firstTab + (parseInt(this.secondTab) + 1);
      this.query();
    },
    Process(data) {
      this.listloading = true;
      this.dialogTitle = "Quotation review";
      this.$ajax
        .cPost(this.source.quotation_api.editQuotation, {
          quotationId: data.qid,
        })
        .then((res) => {
          this.listloading = false;
          if (res.data.result == "true") {
            this.showReviewFLag = true;
            this.thisRow = res.data.data;
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
    ProcessSuccess() {
      this.query();
    },
    edit() {
      this.showEditFLag = true;
      this.showEdit = true;
      this.signOut = false;
      this.dialogTitle = this.$t("lang.NText326");
    },

    deleteQuotation() {
      this.$ajax
        .cPost(this.source.quotation_api.deleteQuotation, {
          quotationId: this.quotationId,
        })
        .then((res) => {
          if (res.data.result == "true") {
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
            this.showDelete = false;
            this.query();
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },

    Review(data) {},
    clickReview() {
      this.showReview = true;
      this.showEdit = false;
      this.signOut = false;
    },

    closeDialog() {
      this.showReviewFLag = false;
      this.showReview = false;
      this.showEdit = false;
      this.thisRow = {};
    },
    clickCancel(data) {
      this.cancelForm = data;
      this.cancelFlag = true;
    },
    Cancel() {
      this.cancelloading = true;
      this.$ajax
        .cPost(this.source.quotation_api.cancellShareQuoate, {
          quotationId: this.cancelForm.qid,
        })
        .then((res) => {
          this.cancelloading = false;
          if (res.data.result == "true") {
            this.cancelFlag = false;
            this.query();
            this.$notify.success({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          } else {
            this.$notify.error({
              title: res.data.message,
              offset: 100,
              duration: 3000,
            });
          }
        });
    },
  },
};
</script>

<style lang="scss">
@import "../../../assets/base.scss";
.qutationSmall {
  width: 860px;
}
.quotationShare {
  padding-bottom: 100px;
  position: relative;
  .list-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .header-left {
      display: flex;
      color: $mainColor;
      font-size: 30px;
      .number {
        font-size: 20px;
        line-height: 35px;
        margin-left: 30px;
      }
    }
  }
  .kSecondHeader {
    margin-bottom: 0;
  }
  .el-tabs__item {
    font-size: 18px;
    color: #828282;
    font-family: "montserratLight";
  }
  .el-tabs__item.is-active {
    color: $background2;
  }
  .el-tabs__nav-wrap::after {
    background-color: #fff;
  }
  .el-tabs__active-bar {
    background-color: $background2;
  }
  .Summry {
    .line1 {
      font-size: 18px;
      color: $background2;
      border-bottom: 1px solid $borderColor1;
      padding-bottom: 10px;
      margin-bottom: 10px;
      font-weight: bold;
    }
    .line2 {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .line3 {
      display: flex;
    }
  }
  .RoleRight {
    display: flex;
    justify-content: space-between;
  }

  .el-table__cell {
    vertical-align: top;
  }
  .RoleRight2 {
    display: flex;
    justify-content: end;

    .icon {
      width: 10px;
      height: 10px;
      margin-left: 5px;
      margin-top: -5px;
    }
  }
  .dialog-header {
    height: 105px;
    padding: 20px;
    background-color: $mainColor;
    display: flex;
    .dw-logo {
      width: 70px;
      height: 70px;
      padding: 15px;
      .svg-icon {
        width: 70px;
        height: 70px;
        color: #fff;
      }
    }
    .close {
      width: 50px;
      height: 50px;
      margin-top: 30px;
      margin-left: 100px;
      cursor: pointer;
      .close-icon {
        width: 50px;
        height: 50px;
        fill: #fff;
        stroke: #fff;
      }
    }
    .dialog-title {
      color: #fff;
      margin-left: 90px;
      text-align: left;
      width: 600px;
      .row1 {
        margin-top: 5px;
      }
      .row2 {
        margin-top: 14px;
        color: $fontColor1;
      }
    }
    .titleDate {
      display: flex;
      border-bottom: 2px solid #fff;
      padding-top: 20px;
      text-align: left;
      height: 60px;
      .updateDate {
        .date {
          font-size: 20px;
          line-height: 30px;
          color: #fff;
          margin-right: 20px;
        }
        .date2 {
          font-size: 20px;
          line-height: 30px;
          color: $background2;
        }
      }
    }
  }
  .Amount {
    font-size: 14px;
    // color: $background2;
    font-family: "montserratLight";
    display: flex;
    .amountType {
      width: 120px;
      color: $fontColor1;
    }
    .headCurrency {
      width: 50px;
    }
    .headAmount {
      text-align: right;
    }
  }
}
</style>
